export default {
    apps: {
        my_infos: 'Le mie info',
        my_experiences: 'Le mie esperienze',
        my_skills: 'Le mie skills',
        github: 'GitHub',
        linkedin: 'LinkedIn',
    },
    my_info: {
        firstname: 'Nome',
        lastname: 'Cognome',
        dob: 'Data di nascita',
        nationality: 'Nazionalità',
        residence: 'Residenza',
        sex: 'Sesso',
        email: 'Email',
        cf: 'Codice fiscale',
        piva: 'P. iva',
    },
    my_experience: {
        activities: 'Attività svolte',
        remote: 'Remoto',
        now: 'Attuale'
    },
    my_skills: {
        OS: 'OS',
        Office: 'Office',
        Language: 'Languages',
        Markup: 'Markup',
        Scripting: 'Scripting Languages',
        Frameworks: 'Frameworks',
        Other: 'Other',
        Database: 'Database',
        WebServer: 'Web Server',
        IDE: 'IDE',
        CMS: 'CMS',
        Versioning: 'Versioning',
        Tools: 'Tools',
    }
}