export default {
    apps:{
        my_infos: 'My Infos',
        my_experiences: 'My Experiences',
        my_skills: 'My Skills',
        github: 'GitHub',
        linkedin: 'LinkedIn',
    },
    my_info: {
        firstname: 'First name',
        lastname: 'Last name',
        dob: 'Ddate of birth',
        nationality: 'Nationality',
        residence: 'Residence',
        sex: 'Sex',
        email: 'Email',
        cf: 'Fiscal code',
        piva: 'VAT number',
    },
    my_experience: {
        activities: 'Activities',
        remote: 'Remote',
        now: 'Now'
    },
    my_skills: {
        OS: 'OS',
        Office: 'Office',
        Language: 'Language',
        Markup: 'Markup',
        Scripting: 'Scripting Languages',
        Frameworks: 'Frameworks',
        Other: 'Other',
        Database: 'Database',
        WebServer: 'WebServer',
        IDE: 'IDE',
        CMS: 'CMS',
        Versioning: 'Versioning',
        Tools: 'Tools',
    }
}