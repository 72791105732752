import { Divider, Grid, Typography } from "@mui/material";

import {
  Edit,
  SimpleForm,
  DateInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  useGetResourceLabel,
} from "react-admin";

import { EditActions, EditToolbar } from "admin/components";

import CustomTitle from "./CustomTitle";
import { transformData } from "./transformData";

import { BankAccountInput, InvoiceLinesInput } from "admin/views/Common";
import { iban } from "admin/core/utils";

const CustomEdit = () => {
  const getResourceLabel = useGetResourceLabel();

  return (
    <Edit
      redirect="list"
      actions={<EditActions />}
      title={<CustomTitle />}
      transform={transformData}
      mutationMode="pessimistic"
      queryOptions={{ meta: { expand: "invoiceLines" } }}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ReferenceInput
              source="companyId"
              validate={required()}
              reference="companies"
              sort={{ field: "name", order: "ASC" }}
              filter={{ userId: null }}
            >
              <SelectInput optionText="name" validate={required()} fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={3} container justifyContent="flex-end">
            <ReferenceInput
              source="invoiceTypeId"
              validate={required()}
              reference="invoicetypes"
              sort={{ field: "order", order: "ASC" }}
            >
              <SelectInput optionText="label" validate={required()} fullWidth />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12} sm={2}>
            <FormDataConsumer fullWidth>
              {({ formData }) => {
                return formData.fiscalYear < new Date().getFullYear() ? (
                  <NumberInput
                    source="number"
                    validate={required()}
                    fullWidth
                  />
                ) : (
                  <NumberInput
                    source="number"
                    disabled
                    InputProps={{ readOnly: true }}
                    fullWidth
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12} sm={8}></Grid>
          <Grid item xs={12} sm={1}>
            <DateInput source="emissionDate" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={12} sm={1}>
            <NumberInput source="fiscalYear" validate={required()} fullWidth />
          </Grid>

          <Grid item xs={12}>
            <Divider flexItem textAlign="center">
              <Typography variant="button">
                {getResourceLabel("invoicelines", 2)}
              </Typography>
            </Divider>
          </Grid>

          <Grid item xs={12}>
            <InvoiceLinesInput source="invoiceLines" label={false} />
          </Grid>

          <Grid item xs={12}>
            <Divider flexItem />
          </Grid>

          <Grid item xs={12} sm={1}>
            <NumberInput
              source="stampAmount"
              disabled
              InputProps={{ readOnly: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ReferenceInput
              source="paymentTypeId"
              validate={required()}
              reference="paymenttypes"
              sort={{ field: "order", order: "ASC" }}
            >
              <SelectInput optionText="label" validate={required()} fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormDataConsumer fullWidth>
              {({ formData }) =>
                formData.paymentTypeId ===
                  "4bd75b1d-b33f-490e-a3a9-1a4d98f77544" && (
                  <BankAccountInput
                    source="bankAccount"
                    validate={[required(), iban()]}
                    fullWidth
                    formClassName="col-12"
                  />
                )
              }
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12} sm={5}></Grid>

          <Grid item xs={12}>
            <TextInput source="otherDetails" fullWidth multiline={true} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default CustomEdit;
