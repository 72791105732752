import React, { Children, useEffect, useState } from 'react';

import {
    AppBar,
    AppBarProps,
    Logout,
    TitlePortal,
    UserMenu,
    usePermissions,
    useStore,
    useTheme,
    useTranslate,
} from 'react-admin';

import { Typography } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { UserProfile } from './UserProfile';
import { Roles } from 'admin/types';

const useStyles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textAlign: 'center'
    },
    role: {
        flex: 1,
        marginRight: 1,
        textAlign: 'right'
    },
    menuItem: {
        borderLeft: 'none',
    },
    spacer: {
        flex: 1,
    },
};

const LogoutButton = (props: any) => <Logout {...props} icon={<ExitToAppIcon />} />;

const CustomUserMenu = ({ permissions, ...props }: any) => (
    <UserMenu {...props}>
        <UserProfile />
        <LogoutButton />
    </UserMenu>
);

export type HeaderProps = AppBarProps & {
    logo?: string
    logoStyle?: any
};

const Header: React.FC<HeaderProps> = (props) => {
    const [theme] = useTheme();
    
    const {
        logo = theme === 'dark' ? '/logo-dark.png' : '/logo.png',
        logoStyle,
        children
    } = props;

    const [userInfo] = useStore<any>('userinfo', null);
    const { isLoading, permissions, refetch } = usePermissions();
    const [roles, setRoles] = useState<any>(null);
    const translate = useTranslate();

    useEffect(() => {
        if (!isLoading && permissions && userInfo) {
            if (permissions.length === 1)
                setRoles(translate(`pos.enums.RoleType.${permissions[0]}`));
            else {
                let roles = permissions.filter(x => x !== Roles.User).map(x => translate(`pos.enums.RoleType.${x}`));
                setRoles(roles.join(' | '));
            }
        }
        else
            setRoles("");
    }, [isLoading, permissions])

    useEffect(() => {
        if (!isLoading) {
            refetch()
        }
    }, [userInfo])

    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu permissions={permissions} />}>
            {logo ? <img src={logo} alt="logo" style={{ ...logoStyle, height: '3vh' }} /> : null}
            {Children.count(children) === 0 ? (
                <Typography
                    sx={useStyles.title}
                    variant="h6"
                    color="inherit"
                />
            ) : (
                children
            )}
            <TitlePortal />
            {
                (!isLoading && permissions && permissions?.length > 0) &&
                <Typography
                    sx={useStyles.role}
                    variant="button"
                    color="inherit"
                >
                    {roles}
                </Typography>
            }
        </AppBar>
    );
};

export default Header;