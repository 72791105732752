import { memo } from 'react';

import {
    Box,
    Divider,
    Link,
    Stack,
    Typography,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { useTranslate } from 'react-admin';

import { AppInfo } from 'publicapp/types';

import { AppWindow } from './base';

import iconImg from '../img/apps/user-sm.png';
import logoImg from '../img/logo.png';

const appName = "myinfos";
const label = "apps.my_infos";

const Component = memo(() => {
    const theme = useTheme();
    const translate = useTranslate();

    return (
        <AppWindow
            name={appName}
            iconUrl={iconImg}
            title={label}
            width='45vw'
            height='45%'
            top='25%'
            left='25vw'
        >
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
                sx={{ height: '100%', width: '100%' }}
            >
                <Box display='flex' justifyContent="center" alignItems="center" sx={{ width: '40%' }} >
                    <img src={logoImg} width={theme.spacing(25)} alt="" />
                </Box>
                <Box display='flex' justifyContent="center" alignItems="center" sx={{ p: 5, width: '60%' }} >
                    <Stack spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <Stack>
                                <Typography variant="h2">
                                    Alessio Filippucci
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
                                    Full-stack Developer
                                </Typography>
                            </Stack>
                        </Stack>

                        <Divider flexItem />

                        <Stack direction="row" spacing={2}>
                            <Stack>
                                <Typography variant="h6" sx={{ width: theme.spacing(20), fontWeight: 'normal' }}>
                                    {translate('my_info.dob')}
                                </Typography>
                                <Typography variant="h6" sx={{ width: theme.spacing(20), fontWeight: 'normal' }}>
                                    {translate('my_info.residence')}
                                </Typography>
                            </Stack>

                            <Stack>
                                <Typography variant="h6">
                                    11/06/1989
                                </Typography>
                                <Typography variant="h6">
                                    Torino
                                </Typography>
                            </Stack>
                        </Stack>

                        <Divider flexItem />

                        <Stack direction="row" spacing={2}>
                            <Stack>
                                <Typography variant="h6" sx={{ width: theme.spacing(20), fontWeight: 'normal' }}>
                                    {translate('my_info.email')}
                                </Typography>
                                <Typography variant="h6" sx={{ width: theme.spacing(20), fontWeight: 'normal' }}>
                                    {translate('my_info.cf')}
                                </Typography>
                                <Typography variant="h6" sx={{ width: theme.spacing(20), fontWeight: 'normal' }}>
                                    {translate('my_info.piva')}
                                </Typography>
                            </Stack>

                            <Stack>
                                <Link
                                    href={`mailto:info@alessiofilippucci.it`}
                                    onClick={e => e.stopPropagation()}
                                    variant="h6"
                                    color={"#d34108"}
                                >
                                    info@alessiofilippucci.it
                                </Link>
                                <Typography variant="h6">
                                    FLPLSS89H11L219W
                                </Typography>
                                <Typography variant="h6">
                                    IT11027640017
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </AppWindow>
    )
})

export const appInfo: AppInfo =
{
    name: appName,
    iconUrl: iconImg,
    label: label,
    component: Component
}