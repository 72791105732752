/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, memo, useEffect, useRef, useState } from 'react';

import { useTheme } from '@mui/material/styles';

import {
    Box,
    DialogContent,
    DialogTitle,
    Paper,
    Stack,
    Typography,
} from '@mui/material';

import Draggable from 'react-draggable';

import { useTranslate, useStore } from 'react-admin';

import { AppProps } from './index';

import minimizeIconUrl from 'publicapp/img/ui/minimize.png';
import maxminIconUrl from 'publicapp/img/ui/maxmin.png';
import maximizeIconUrl from 'publicapp/img/ui/maximize.png';
import closeIconUrl from 'publicapp/img/ui/close.png';

import { AppInfo, AppStatusType } from 'publicapp/types';

export interface AppWindowProps extends AppProps {
    iconUrl: string;
    title: string;
    children: ReactNode | ReactNode[];
    width?: string | number;
    height?: string | number;
    top?: string | number;
    left?: string | number;
}

const nextZIndex: () => number = () => {
    let maxZ = 0;
    for (let w of document.querySelectorAll<HTMLDivElement>(
        ".window-container"
    )) {
        let z = parseInt(w.style.zIndex);
        maxZ = Math.max(isNaN(z) ? 0 : z, maxZ);
    }
    return maxZ + 1;
};

const AppWindow = memo((props: AppWindowProps) => {
    const {
        name,
        iconUrl,
        title,
        children,
        width = '60vw',
        height = '60%',
        top = '10%',
        left = '20%',
    } = props;

    const nodeRef = useRef(null);
    const [applist, setApplist] = useStore<AppInfo[]>('applist');
    const [app, setApp] = useStore<AppInfo>(`app.${name}`);

    const theme = useTheme();
    const translate = useTranslate();
    const [open, setOpen] = useState<boolean>(false);
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [level, setLevel] = useState<number>(nextZIndex());

    useEffect(() => {
        switch (app.status) {
            case AppStatusType.minimized:
                if (open) {
                    handleMinimize();
                }
                break;

            case AppStatusType.opened:
                if (app.active) {
                    setOpen(true);
                    setVisible();
                }
                break;
            default:
                return () => { };
        }
        return () => { };
    }, [app.active, app.status])

    const handleMouseDown = () => {
        if (!app?.active) {
            setVisible();
        }
    };

    const setVisible = () => {
        app.status = AppStatusType.opened;
        app.active = true;

        setLevel(nextZIndex());
        setApp(app);
        setApplist(applist.map(_app => {
            if (_app.name === name) {
                return app;
            }
            else {
                _app.active = false;
                return _app;
            }
        }));
    }

    const setHidden = () => {
        app.status = AppStatusType.closed;
        app.active = false;

        let toOpen = applist.find(x => x.status === AppStatusType.opened && x.name !== app.name);

        setLevel(1);
        setApp(app);
        setApplist(applist.map(_app => {
            if (_app.name === name) {
                return app;
            }
            else {
                if (_app.name === toOpen?.name)
                    _app.active = true;
                return _app;
            }
        }));
        setOpen(false);
    }

    const handleMinimize = () => {
        app.status = AppStatusType.minimized;
        app.active = false;

        let toOpen = applist.find(x => x.status === AppStatusType.opened && x.name !== app.name);

        setLevel(1);
        setApp(app);
        setApplist(applist.map(_app => {
            if (_app.name === name) {
                return app;
            }
            else {
                if (_app.name === toOpen?.name)
                    _app.active = true;
                return _app;
            }
        }));
        setOpen(false);
    };

    const handleMaxmin = () => {
        setFullScreen(!fullScreen);
    };

    const handleClose = (event: object, reason: string) => {
        if (!(reason === 'escapeKeyDown' || reason === 'backdropClick')) {
            setHidden();
        }
    };

    if (!open && app.status === AppStatusType.closed) return null;

    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"], [id="dialog-title-action"]'}
            nodeRef={nodeRef}
            onMouseDown={() => handleMouseDown()}
            bounds="parent"
        >
            <Paper
                id={`${name}_win`}
                className="window-container"
                sx={{
                    display: open ? 'flex' : 'none',
                    position: fullScreen ? 'fixed' : 'absolute',
                    transform: fullScreen ? 'translate(0, 0) !important' : null,
                    top: fullScreen ? '0' : top,
                    left: fullScreen ? '0' : left,
                    width: fullScreen ? '100vw' : width,
                    height: fullScreen ? 'calc(100vh - 48px)' : height,
                    borderRadius: fullScreen ? 0 : theme => theme.spacing(1),
                    border: 'solid 1px #ddd',
                }}
                style={{
                    zIndex: level,
                }}
                ref={nodeRef}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <DialogTitle
                        id="draggable-dialog-title"
                        component="div"
                        sx={{
                            margin: 0,
                            pl: theme => theme.spacing(1),
                            pr: 0,
                            py: 0,
                            background: '#f3f3f3 radial-gradient(#eff4f9 75%, #f3f3f3 100%) no-repeat fixed',
                            color: '#222',
                            cursor: fullScreen ? null : 'move',
                            border: 'solid 0 #ddd',
                            borderBottomWidth: 1,
                            borderRadius: fullScreen ? 0 : theme => `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
                            height: 30
                        }}
                    >
                        <Stack
                            spacing={.5}
                            direction="row"
                            alignItems="center"
                        >
                            <Box>
                                <img src={iconUrl} width={theme.spacing(2)} alt={translate(title)} />
                            </Box>
                            <Typography variant="body1" gutterBottom textAlign='center'>
                                {translate(title)}
                            </Typography>
                            <Box
                                id="dialog-title-action"
                                style={{ marginLeft: "auto", cursor: 'default' }}
                                justifyContent="flex-end"
                            >
                                <Stack
                                    spacing={0}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box
                                        onClick={handleMinimize}
                                        sx={{
                                            px: theme.spacing(2),
                                            '&:hover': {
                                                backgroundColor: 'rgba(136,136,136,.2)',
                                            },
                                        }}
                                    >
                                        <img src={minimizeIconUrl} width={theme.spacing(1.5)} alt="minimize" />
                                    </Box>
                                    <Box
                                        onClick={handleMaxmin}
                                        sx={{
                                            px: theme.spacing(2),
                                            '&:hover': {
                                                backgroundColor: 'rgba(136,136,136,.2)',
                                            },
                                        }}
                                    >
                                        <img src={fullScreen ? maximizeIconUrl : maxminIconUrl} width={theme.spacing(1.5)} alt="maximize" />
                                    </Box>
                                    <Box
                                        onClick={(e) => handleClose(e, '')}
                                        sx={{
                                            px: theme.spacing(2),
                                            borderRadius: fullScreen ? 0 : theme => `0 ${theme.spacing(1)} 0 0`,
                                            '&:hover': {
                                                backgroundColor: 'rgba(255,0,0,.8)',
                                                '&img': {
                                                    filter: 'invert(1)'
                                                },
                                            },
                                        }}
                                    >
                                        <img src={closeIconUrl} width={theme.spacing(1.5)} alt="close" />
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>
                    </DialogTitle>
                    <DialogContent
                        className='win11Scroll'
                        sx={{
                            margin: 0,
                            background: theme => theme.palette.common.white,
                            color: '#222',
                            height: 'calc(100% - 30px)',
                            borderRadius: fullScreen ? 0 : theme => `0 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
                        }}
                    >
                        {children}
                    </DialogContent>
                </Box>
            </Paper>
        </Draggable>
    )
})

export default AppWindow;