import { useFormContext } from "react-hook-form";
import classNames from "classnames";

import { IconButton, Tooltip } from "@mui/material";
import Icon from "@mui/icons-material/FileCopy";
import { makeStyles } from "@mui/styles";

import {
  TextInput,
  useGetIdentity,
  useNotify,
  useTranslate,
  useDataProvider,
  TextInputProps,
} from "react-admin";

import { Company } from "admin/types";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  form: { padding: 0 },
  toolbar: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  clearIcon: {
    height: 16,
    width: 0,
  },
  visibleClearIcon: {
    width: 16,
  },
  copyButton: {
    height: 24,
    width: 24,
    padding: 0,
  },
});

const BankAccountInput = (props: TextInputProps) => {
  const { source } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const { setValue } = useFormContext();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { data: identity } = useGetIdentity();

  const handleCopy = () => {
    if (identity) {
      dataProvider
        .getList<Company>("companies", {
          filter: { userId: identity.id },
          sort: { field: "name", order: "DESC" },
          pagination: { page: 1, perPage: 1 },
        })
        .then((response) => {
          const { data } = response || { data: [] };
          if (data.length > 0) {
            setValue(source, data[0].bankAccount);
          }
        })
        .catch((error) => {
          console.log(error);
          notify("Error", { type: "warning" });
        })
        .finally(() => {});
    }
  };

  return (
    <TextInput
      {...props}
      InputProps={{
        endAdornment: (
          <Tooltip title={translate("invoices.action.copyBankAccount")}>
            <IconButton
              className={classes.copyButton}
              disableRipple
              onClick={handleCopy}
            >
              <Icon
                className={classNames(
                  classes.clearIcon,
                  classes.visibleClearIcon
                )}
              />
            </IconButton>
          </Tooltip>
        ),
      }}
    />
  );
};

export default BankAccountInput;
